import React from "react"
import firebase, { auth } from "firebase"
import axios from "axios"
import { navigate, Link } from "gatsby"

import { handleSignOut } from "./signout"
import { isBrowser } from "services/general"
import { isAccessCodeValid } from "./permissions"
import VerifyEmailModal from "../VerifyEmailModal"

import {
  GATSBY_WEBSITE_URL,
  GATSBY_ENV,
  GATSBY_AUTH,
  GATSBY_TELECONSULT_WEBSITE_URL,
} from "gatsby-env-variables"
import { getSignedInUser } from "./user"

export const getUserData = async ({ authUid }) => {
  const filteredUsers = await firebase
    .firestore()
    .collection("users")
    .where("authUid", "==", authUid)
    .get()
  let userData
  filteredUsers.forEach((user) => {
    userData = { ...user.data(), id: user.id }
  })

  if (userData) userData.permissions = await getUserPermissions(userData)

  return userData
}

export const getUserPermissions = async ({ accessCode = "", email }) => {
  let accessRule
  // check email
  let userDomain = email.split("@")[1]

  const accessRulesViaDomain = await firebase
    .firestore()
    .collection("whitelist")
    .where("subdomain", "==", GATSBY_WEBSITE_URL)
    .where("domains", "array-contains", userDomain)
    .get()

  accessRulesViaDomain.forEach((rule) => {
    accessRule = { ...rule.data(), id: rule.id }
  })

  if (accessRule)
    return accessRule.programCodes.map((code) => Buffer.from(code))

  if (accessCode && accessCode !== "") {
    const accessRulesViaCode = await firebase
      .firestore()
      .collection("accessRules")
      .where("code", "==", accessCode)
      .get()

    accessRulesViaCode.forEach((rule) => {
      accessRule = { ...rule.data(), id: rule.id }
    })
  }
  if (accessRule)
    return accessRule.programCodes.map((code) => Buffer.from(code))

  // domain not included and access code provided and no access code provided
  return []
}

export const decodePermissions = (permissions) => {
  return permissions?.map((permission) => Buffer(permission).toString()) || []
}

export const getUserAddresses = async ({ addressesId }) => {
  let filteredAddress = await firebase
    .firestore()
    .collection("addresses")
    .doc(addressesId || " ")
    .get()
  if (filteredAddress.data())
    filteredAddress = {
      ...filteredAddress.data(),
      id: filteredAddress.id,
    }
  else filteredAddress = {}

  return filteredAddress
}

export const handleEmailLogin = async ({
  values,
  setMessage,
  errorCallback,
  dispatch,
  setLoading,
  location,
}) => {
  let email = values?.email
  let password = values?.password

  try {
    // if (!!user?.authUid) {
    // Sign in user
    let response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)

    // Handle if emil is verified through email verification
    const isVerified =
      GATSBY_ENV === "production" ? response?.user?.emailVerified : true

    if (isVerified) {
      let idToken = await response.user.getIdToken(true)
      await axios.post(
        GATSBY_AUTH + "/login",
        { idToken },
        { withCredentials: true }
      )
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

      const authUid = response?.user?.uid
      let userData = (await getUserData({ authUid })) || {}

      let addressesId = userData?.addresses
      let userAddresses = await getUserAddresses({ addressesId })

      // Save user data and addresses to session
      if (isBrowser()) {
        sessionStorage.setItem("userData", JSON.stringify({ ...userData }))
        sessionStorage.setItem("addresses", JSON.stringify(userAddresses))
      }

      // if (resetForm) resetForm()
      // if (callBack) callBack()

      if (userData?.forceReset)
        return navigate("/profile/update-password", {
          state: { forceReset: true, nextPath: "/profile/edit/" },
        })

      if (!userData?.accessCode) return navigate("/access-code")

      if (isBrowser()) {
        let url = localStorage.getItem("url")
        let program = localStorage.getItem("program")
        localStorage.removeItem("url")
        localStorage.removeItem("program")

        if (url && program)
          window.location.href = `${GATSBY_TELECONSULT_WEBSITE_URL}booking/[$URL]/intake?program=[$PROGRAM]`
            .replace("[$URL]", url)
            .replace("[$PROGRAM]", program)
      }

      return navigate("/")
    } else {
      // If not verified, prevent login and show modal
      await handleSignOut({ redirect: false })
      setLoading(false)
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Your email is not verified",
          isCard: true,
          headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
          content: (
            <VerifyEmailModal user={response?.user} location={location} />
          ),
        },
      })
      return
    }
  } catch (error) {
    console.log(error)
    if (errorCallback) errorCallback()

    switch (error?.code) {
      case "auth/wrong-password":
        setMessage({
          type: "danger",
          content: {
            code: error.code,
            message:
              "Invalid email or password. Please check if your credentials are correct before logging in again.",
          },
        })
        break

      case "auth/user-not-found":
        setMessage({
          type: "danger",
          content: {
            code: error.code,
            message: (
              <span>
                The email you entered does not match our records. Please{" "}
                <Link to="/verify-email">enroll</Link> to avail of our services
              </span>
            ),
          },
        })
        break

      default:
        setMessage({
          type: "danger",
          content: { code: error.code, message: error.message },
        })
    }
  }
}

export const handleCustomSignIn = async ({ token }) => {
  let response = await firebase.auth().signInWithCustomToken(token)

  try {
    const isVerified =
      GATSBY_ENV === "production" ? response?.user?.emailVerified : true

    if (isVerified) {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

      const authUid = response?.user?.uid
      let userData = (await getUserData({ authUid })) || {}

      let addressesId = userData?.addresses
      let userAddresses = await getUserAddresses({ addressesId })

      // Save user data and addresses to session
      if (isBrowser()) {
        sessionStorage.setItem("userData", JSON.stringify({ ...userData }))
        sessionStorage.setItem("addresses", JSON.stringify(userAddresses))
      }

      if (userData?.forceReset) {
        return navigate("/profile/update-password", {
          state: {
            forceReset: true,
            nextPath: "/profile/edit/",
          },
        })
      }
      return navigate("/")
    } else {
      // If not verified, prevent login and show modal
      await handleSignOut({ redirect: false })
      return
    }
  } catch (error) {
    console.log(error)
  }
}

export const handleEmailLoginAfterResetPassword = async ({
  email,
  password,
}) => {
  try {
    let response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)

    const isVerified =
      GATSBY_ENV === "production" ? response?.user?.emailVerified : true

    if (isVerified) {
      let idToken = await response.user.getIdToken(true)
      await axios.post(
        GATSBY_AUTH + "/login",
        { idToken },
        { withCredentials: true }
      )
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

      const authUid = response?.user?.uid
      let userData = (await getUserData({ authUid })) || {}

      let addressesId = userData?.addresses
      let userAddresses = await getUserAddresses({ addressesId })

      // Save user data and addresses to session
      if (isBrowser()) {
        sessionStorage.setItem("userData", JSON.stringify({ ...userData }))
        sessionStorage.setItem("addresses", JSON.stringify(userAddresses))
      }
      return navigate("/")
    } else {
      // If not verified, prevent login and show modal
      await handleSignOut({ redirect: false })
      return
    }
  } catch (error) {
    console.log(error)
  }
}

export const handleAccessCode = async ({
  values,
  setMessage,
  setLoading,
  callback,
}) => {
  let accessCode = values?.accessCode
  let response
  try {
    const valid = await isAccessCodeValid(accessCode)

    if (!valid)
      throw Error(
        "Access code not found, please make sure you that you are using the correct access code provided by your company."
      )

    let { userData } = getSignedInUser()

    if (userData?.id) {
      response = await firebase
        .firestore()
        .collection("users")
        .doc(userData?.id)
        .update({ accessCode })
      if (isBrowser()) {
        // sessionStorage.removeItem("userData")
        sessionStorage.setItem(
          "userData",
          JSON.stringify({
            ...userData,
            accessCode,
            email: auth().currentUser.email,
            id: response?.id || userData?.id,
          })
        )
      }
    } else {
      sessionStorage.setItem("accessCode", accessCode)
    }

    if (callback) callback()
    setLoading(false)
  } catch (error) {
    setLoading(false)
    setMessage({
      type: "danger",
      content: {
        code: error?.code,
        message: <span>{error?.message}</span>,
      },
    })
  }
}

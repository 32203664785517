import React, { Fragment, memo, useMemo, useState } from "react"
import axios from "axios"

import Loading from "elements/Loading"
import { isBrowser } from "../../services/general"
import { hasSignedInUser } from "./services/user"
import { handleCustomSignIn } from "./services/signin"
import { handleSignOut } from "./services/signout"

import { GATSBY_AUTH } from "gatsby-env-variables"

const AuthRoute = ({ children }) => {
  const [loading, setLoading] = useState(false)
  useMemo(() => {
    const fetchUserData = async () => {
      if (isBrowser() && !hasSignedInUser()) {
        try {
          setLoading(true)
          let res = await axios(GATSBY_AUTH + "/verify", {
            method: "post",
            withCredentials: true,
          })
          if (res.data.token)
            await handleCustomSignIn({ token: res.data.token })
          setLoading(false)
        } catch (error) {
          await handleSignOut({ redirect: false })
          return setLoading(false)
        }
      }
    }
    fetchUserData()
  }, [])

  return <Fragment>{loading ? <Loading /> : children}</Fragment>
}

export default memo(AuthRoute)

import React, { Fragment } from "react"
import Loading from "elements/Loading"
import { navigate } from "gatsby"
import { isBrowser } from "../../services/general"
import { hasSignedInUser } from "../Auth/services/user"

const PrivateRoute = ({ isPrivate, children }) => {
  if (isPrivate && isBrowser()) {
    switch (true) {
      // case hasSignedInUser() && !hasRoles():
      //   navigate("/profile/edit")
      //   break
      case !hasSignedInUser():
        navigate("/verify-email")
        return <Loading />

      // case requireProfileComplete && !!hasSignedInUser() && userData.forceReset:
      //   navigate("/profile/update-password", {
      //     state: {
      //       forceReset: true,
      //     },
      //   })
      //   break

      // case requireProfileComplete &&
      //   !!hasSignedInUser() &&
      //   !isProfileComplete({ profileFormFields, userData }):
      //   navigate("/profile/edit", {
      //     state: {
      //       title: "Complete Profile",
      //       nextPath: "/booking/coaching/intake/",
      //       subtitle:
      //         "Please complete your profile before scheduling a coaching session.",
      //     },
      //   })
      //   break
      default:
        break
    }
  }

  return <Fragment>{children}</Fragment>
}

export default PrivateRoute

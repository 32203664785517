import { navigate } from "gatsby"
import firebase from "firebase"
import { decodePermissions } from "./signin"
import {
  GATSBY_WEBSITE_URL,
  GATSBY_MENTAL_HEALTH_PROGRAM_CODE,
  GATSBY_PHYSICAL_HEALTH_PROGRAM_CODE,
} from "gatsby-env-variables"

export const isEmailDomainValid = async (email) => {
  let accessRule
  let userDomain = email.split("@")[1]

  const accessRulesViaDomain = await firebase
    .firestore()
    .collection("whitelist")
    .where("subdomain", "==", GATSBY_WEBSITE_URL)
    .where("domains", "array-contains", userDomain)
    .get()

  accessRulesViaDomain.forEach((rule) => {
    accessRule = { ...rule.data(), id: rule.id }
  })

  if (accessRule) return true
  return false
}

export const isAccessCodeValid = async (accessCode) => {
  let accessRule
  if (accessCode && accessCode !== "") {
    const accessRulesViaCode = await firebase
      .firestore()
      .collection("accessRules")
      .where("code", "==", accessCode)
      .get()

    accessRulesViaCode.forEach((rule) => {
      accessRule = { ...rule.data(), id: rule.id }
    })
  }

  if (accessRule) return true

  return false
}

export const redirectBasedOnPermissions = (chosen, permissions) => {
  const program =
    chosen === "Physical Health"
      ? GATSBY_PHYSICAL_HEALTH_PROGRAM_CODE.toString()
      : GATSBY_MENTAL_HEALTH_PROGRAM_CODE.toString()
  const decodedPermissions = decodePermissions(permissions)
  const valid = decodedPermissions.includes(program)

  if (valid) {
    const programUrl = chosen === "Physical Health" ? "body" : "mind"
    navigate(`/booking/${programUrl}/intake`)
  } else {
    navigate(`/`, { state: { invalid: true } })
  }
}

import firebase from "firebase"
import axios from "axios"
import { navigate } from "gatsby"
import { isBrowser } from "services/general"

import { GATSBY_AUTH } from "gatsby-env-variables"

export const handleSignOut = async ({ redirect = true }) => {
  try {
    firebase.auth().signOut()
    await axios(GATSBY_AUTH + "/logout", {
      method: "post",
      withCredentials: true,
    })
  } catch (error) {}

  if (isBrowser()) {
    sessionStorage.clear()
    localStorage.clear()
    if (redirect)
      if (window.location.pathname) window.location.reload()
      else return navigate("/sign-in")
  }
}

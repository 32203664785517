import _ from "lodash"
import { camelizeKeys } from "humps"
import { isBrowser } from "services/general"
import { GATSBY_WEBSITE_URL } from "gatsby-env-variables"

export const hasSignedInUser = () => {
  if (isBrowser()) {
    const USER =
      Object.keys(window.sessionStorage).filter((item) =>
        item.startsWith("firebase:authUser")
      )[0] || "medstaffData" in window.sessionStorage

    return USER
  }
}

export const getSignedInUser = () => {
  if (isBrowser()) {
    let fauthUser = Object.keys(window.sessionStorage).filter((item) =>
      item.startsWith("firebase:authUser")
    )[0]
    let authUser = JSON.parse(sessionStorage.getItem(fauthUser))
    // let authUser = JSON.parse(sessionStorage.getItem(authUser))
    let userData = camelizeKeys(JSON.parse(sessionStorage.getItem("userData")))
    let accessCode = sessionStorage.getItem("accessCode")
    let addresses = JSON.parse(sessionStorage.getItem("addresses"))
    let coaches = JSON.parse(sessionStorage.getItem("coaches"))
    let healthSurveys = JSON.parse(sessionStorage.getItem("healthSurveys"))

    return { accessCode, authUser, userData, addresses, coaches, healthSurveys }
  } else return {}
}

export const hasRoles = () => {
  const { userData } = getSignedInUser()

  let hasRoles = false

  if (userData) {
    let activeRole = userData?.roles?.find(
      (role) =>
        role?.subdomain === GATSBY_WEBSITE_URL && role?.status === "active"
    )
    if (activeRole) hasRoles = true
  }

  return hasRoles
}

export const isEmailVerified = () => {
  const { authUser } = getSignedInUser()

  if (authUser && authUser.emailVerified) {
    return true
  }
  return false
}

export const isProfileComplete = () => {
  const { userData } = getSignedInUser()

  let requiredEnrollmentFields = [
    "firstName",
    "lastName",
    "birthday",
    "birthday.year",
    "birthday.month",
    "birthday.date",
    "sex",
    "email",
    "mobileNumber",
    "emergencyContact",
    "emergencyContact.name",
    "emergencyContact.relationship",
    "emergencyContact.mobileNumber",
    "company",
    "department",
  ]

  if (userData?.domain?.siteOptions?.length > 0) {
    requiredEnrollmentFields.push("site")
  }

  // profileComplete is true if all required fields have a value
  let profileComplete = requiredEnrollmentFields.every((field) => {
    return !!_.get(userData, field)
  })

  return profileComplete
}
